import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { gridItems } from "../data";
import { BentoGrid, BentoGridItem } from "./ui/BentoGrid";

const Grid = () => {
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById("about");
      if (section) {
        const { top } = section.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (top <= windowHeight * 0.75) {
          controls.start({ opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } });
        } else {
          controls.start({ opacity: 0, y: 30 });
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check

    return () => window.removeEventListener("scroll", handleScroll);
  }, [controls]);

  return (
    <section id="about">
      <motion.div
        className="w-full py-20"
        initial={{ opacity: 0, y: 30 }}
        animate={controls}
      >
        <BentoGrid>
          {gridItems.map((item, i) => (
            <BentoGridItem
              id={item.id}
              key={i}
              title={item.title}
              description={item.description}
              className={item.className}
              img={item.img}
              imgClassName={item.imgClassName}
              titleClassName={item.titleClassName}
              spareImg={item.spareImg}
            />
          ))}
        </BentoGrid>
      </motion.div>
    </section>
  );
};

export default Grid;