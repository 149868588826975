import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { workExperience } from "../data";
import { Button } from "./ui/MovingBorders";

const Experience = () => {
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById("work");
      if (section) {
        const { top } = section.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (top <= windowHeight * 0.75) {
          controls.start({ opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } });
        } else {
          controls.start({ opacity: 0, y: 30 });
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check

    return () => window.removeEventListener("scroll", handleScroll);
  }, [controls]);

  return (
    <div className="py-20 w-full">
      <h1 className="heading text-white" id="work">
        Servicios de <span className="text-gray-400"> Verificación</span> y Auditoría
      </h1>

      <motion.div
        className="w-full mt-12 grid lg:grid-cols-4 grid-cols-1 gap-10"
        initial={{ opacity: 0, y: 30 }}
        animate={controls}
      >
        {workExperience.map((card) => (
          <Button
            key={card.id}
            duration={Math.floor(Math.random() * 10000) + 10000}
            borderRadius="1.75rem"
            style={{
              background: "rgb(4,7,29)",
              backgroundColor:
                "linear-gradient(90deg, rgba(4,7,29,1) 0%, rgba(12,14,35,1) 100%)",
              borderRadius: `calc(1.75rem* 0.96)`,
            }}
            className="flex-1 text-black dark:text-white border-neutral-200 dark:border-slate-800"
          >
            <div className="flex lg:flex-row flex-col lg:items-center p-3 py-6 md:p-5 lg:p-10 gap-2">
              <img
                src={card.thumbnail}
                alt={card.thumbnail}
                className="lg:w-32 md:w-20 w-16"
              />
              <div className="lg:ms-5">
                <h1 className="text-start text-xl md:text-2xl font-bold text-white">
                  {card.title}
                </h1>
                <p className="text-start text-white-100 mt-3 font-semibold">
                  {card.desc}
                </p>
              </div>
            </div>
          </Button>
        ))}
      </motion.div>
    </div>
  );
};

export default Experience;
