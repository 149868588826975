import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';

const ProcessStages = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const stages = [
    {
      number: "01",
      title: "Diagnóstico Estratégico",
      description: "Análisis profundo de la situación actual, identificando fortalezas, debilidades y oportunidades de mejora.",
      additionalInfo: "Insights Estratégicos",
    },
    {
      number: "02",
      title: "Diseño de Solución",
      description: "Desarrollo de estrategias personalizadas alineadas con los objetivos específicos de tu organización.",
      additionalInfo: "Innovación Adaptativa",
    },
    {
      number: "03",
      title: "Implementación",
      description: "Ejecución meticulosa de las estrategias, con acompañamiento cercano y ajustes en tiempo real.",
      additionalInfo: "Mejora Continua",
    },
  ];

  return (
    <section className="bg-[#0a0a0a] text-white py-32 px-6 selection:bg-white/10 mb-20">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
          className="text-center mb-8 md:mb-20"
        >
          <h2
            className="text-4xl md:text-6xl font-extralight tracking-tight mb-3 mt-2 
              bg-gradient-to-r from-white/90 via-white/80 to-white/60 
              bg-clip-text text-transparent
              leading-tight"
          >
            Enfoque VEO
          </h2>
          <p className="text-white/70 max-w-3xl mx-auto text-xl md:text-2xl font-light leading-relaxed tracking-tight">
            Convertimos desafíos complejos en soluciones estratégicas personalizadas, impulsando la evolución sostenible de tu organización.
          </p>
        </motion.div>

        <div ref={ref} className="grid md:grid-cols-3 gap-8 md:gap-16">
          {stages.map((stage, index) => (
            <div key={stage.number} className="space-y-6">
              <motion.div
                initial={{
                  opacity: 0,
                  y: 60,
                  scale: 0.97,
                }}
                animate={
                  isInView
                    ? {
                        opacity: 1,
                        y: 0,
                        scale: 1,
                      }
                    : {}
                }
                transition={{
                  duration: 0.9,
                  delay: index * 0.25,
                  type: "spring",
                  stiffness: 40,
                }}
                className="relative group perspective-1000 transform-gpu"
              >
                <div
                  className="absolute inset-0 
                    bg-white/5 
                    border border-white/10 
                    rounded-2xl 
                    opacity-0 
                    group-hover:opacity-100 
                    transition-all duration-700 
                    blur-sm group-hover:blur-none 
                    scale-[1.02] 
                    group-hover:scale-105"
                />

                <div
                  className="relative z-10 p-4 md:p-8 space-y-4 md:space-y-6 bg-[#0a0a0a] rounded-2xl 
                    border border-white/10 
                    shadow-2xl 
                    group-hover:border-white/20 
                    transition-all duration-500 
                    min-h-[320px] md:min-h-[480px] flex flex-col"
                >
                  <div className="flex items-center justify-between">
                    <div
                      className={`
                        text-4xl md:text-5xl font-light tracking-tighter
                        text-white/60 
                        group-hover:text-white/90 
                        transition-all duration-500
                      `}
                    >
                      {stage.number}
                    </div>
                  </div>

                  <h3
                    className="text-3xl md:text-4xl font-light 
                      text-white/90 
                      group-hover:text-white 
                      transition-colors duration-500 
                      leading-tight flex-grow"
                  >
                    {stage.title}
                  </h3>

                  <p
                    className="text-white/60 text-base md:text-lg 
                      leading-relaxed 
                      group-hover:text-white/80 
                      transition-colors duration-500 flex-grow"
                  >
                    {stage.description}
                  </p>
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={isInView ? { opacity: 1, y: 0 } : {}}
                transition={{
                  duration: 0.7,
                  delay: index * 0.25 + 0.5,
                }}
                className="text-center"
              >
                <p className="text-white/50 text-xs md:text-base tracking-wide uppercase">
                  {stage.additionalInfo}
                </p>
              </motion.div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProcessStages;