

export const gridItems = [
    {
      id: 1,
      title: "Verificación clara y precisa de inmuebles y obras",
      description: "",
      className: "lg:col-span-3 md:col-span-6 md:row-span-4 lg:min-h-[60vh]",
      imgClassName: "w-full h-full",
      titleClassName: "justify-end",
      img: "/verificacion.jpeg",
      spareImg: "",
    },
    {
      id: 2,
      title: "Consultoría en Sostenibilidad",
      description: "",
      className: "lg:col-span-2 md:col-span-3 md:row-span-2",
      imgClassName: "",
      titleClassName: "justify-start",
      img: "",
      spareImg: "",
    },
    {
      id: 3,
      title: "Análisis de Mercado",
      description: "Oportunidades y tendencias.",
      className: "lg:col-span-2 md:col-span-3 md:row-span-2",
      imgClassName: "",
      titleClassName: "justify-center",
      img: "",
      spareImg: "",
    },
    {
    id: 4,
    title: "Experiencia en Evaluación de Inmuebles",
    description: "",
    className: "lg:col-span-2 md:col-span-3 md:row-span-1",
    imgClassName: "",
    titleClassName: "justify-start text-sm font-light", 
    img: "/image.png",
  },
  
  
    {
      id: 5,
      title: "Optimización de Procesos de Compra",
      description: "Asesoría para decisiones inmobiliarias seguras.",
      className: "md:col-span-3 md:row-span-2",
      imgClassName: "absolute right-0 bottom-0 md:w-96 w-60",
      titleClassName: "justify-center md:justify-start lg:justify-center",
      img: "/image-2.png",
      spareImg: "/grid.svg",
    },
    {
      id: 6,
      title: "",
      description: "",
      className: "lg:col-span-2 md:col-span-3 md:row-span-1",
      imgClassName: "",
      titleClassName: "",
      img: "/buildings.jpg",
      spareImg: "",
    },
    
    
  ];
  
  
  export const workExperience = [
    {
      id: 1,
      title: "Revisión de Planos",
      desc: "Analizamos los planos y documentos para garantizar precisión y conformidad con las normativas.",
      className: "md:col-span-2",
      thumbnail: "/building-line.svg",
    },
    {
      id: 2,
      title: "Inspección en Sitio",
      desc: "Realizamos visitas periódicas para verificar que la obra avance según el cronograma y especificaciones.",
      className: "md:col-span-2",
      thumbnail: "/building-2-fill.svg",
    },
    {
      id: 3,
      title: "Control de Calidad Final",
      desc: "Evaluamos el acabado y funcionalidad de la obra antes de la entrega para garantizar el cumplimiento de los estándares.",
      className: "md:col-span-2",
      thumbnail: "/ball-pen-fill.svg",
    },
    {
      id: 4,
      title: "Verificación de Materiales",
      desc: "Comprobamos la calidad y autenticidad de los materiales utilizados para asegurar la durabilidad de la construcción.",
      className: "md:col-span-2",
      thumbnail: "/git-repository-private-line.svg",
    },
    {
      id: 5,
      title: "Registro de Ventas",
      desc: "Mantenemos un registro detallado de todas las ventas realizadas, asegurando la transparencia y precisión en la documentación.",
      className: "md:col-span-2",
      thumbnail: "/macbook-fill.svg", 
    },
    {
      id: 6,
      title: "Adquisiciones",
      desc: "Gestionamos las adquisiciones de materiales y servicios necesarios para el proyecto, garantizando la calidad y el cumplimiento de los requisitos.",
      className: "md:col-span-2",
      thumbnail: "/wallet-2-fill.svg", 
    },
    {
      id: 7,
      title: "Consultoría en Adquisición Minera",
      desc: "Asesoría especializada en adquisición de minas y derechos de explotación en Argentina, brindando seguridad y claridad en cada etapa del proceso.",
      className: "md:col-span-2",
      thumbnail: "/caravan-line.svg",
    },
    {
      id: 8,
      title: "Servicios de Due Diligence",
      desc: "Análisis técnico y legal previo a la compra para identificar riesgos y oportunidades en proyectos mineros.",
      className: "md:col-span-2",
      thumbnail: "/bar-chart-2-line.svg",
    },
    
  ];
  
  
  export const socialMedia = [
    {
      id: 1,
      img: "/git.svg",
    },
    {
      id: 2,
      img: "/twit.svg",
    },
    {
      id: 3,
      img: "/link.svg",
    },
  ];
  