import React, { useEffect, useRef, useState } from 'react';
import { Scale, FileSearch, Handshake, BarChart } from 'lucide-react';

const MiningSection = () => {
  const [isVisible, setIsVisible] = useState({
    image: false,
    title: false,
    features: [false, false, false, false]
  });

  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setIsVisible({
            image: true,
            title: true,
            features: [true, true, true, true]
          });
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const features = [
    {
      icon: <Scale className="w-10 h-10 text-blue-300 stroke-[1.5]" />,
      title: "Asesoramiento Legal",
      description: "Análisis de títulos y normativa minera vigente."
    },
    {
      icon: <FileSearch className="w-10 h-10 text-green-300 stroke-[1.5]" />,
      title: "Gestión de Permisos",
      description: "Tramitación de permisos de exploración y explotación."
    },
    {
      icon: <Handshake className="w-10 h-10 text-purple-300 stroke-[1.5]" />,
      title: "Negociación",
      description: "Representación y redacción de acuerdos personalizados."
    },
    {
      icon: <BarChart className="w-10 h-10 text-indigo-300 stroke-[1.5]" />,
      title: "Planificación Estratégica",
      description: "Estrategias para maximizar rendimiento y cumplimiento."
    }
  ];

  return (
    <section ref={sectionRef} className="bg-[#0a0a0a] text-white min-h-screen flex items-center">
      <div className="max-w-7xl mx-auto px-8 w-full">
        <div className="grid lg:grid-cols-2 gap-16 items-center">
          {/* Imagen con animación */}
          <div 
            className={`relative overflow-hidden rounded-2xl group transition-all duration-1000 
              ${isVisible.image 
                ? 'opacity-100 translate-x-0' 
                : 'opacity-0 -translate-x-10'
              } h-[400px] md:h-[600px] mt-8 md:mt-0`}
          >
            <div className="absolute inset-0 bg-black/30 group-hover:bg-black/10 transition-all duration-700 z-10"></div>
            <img
              src="/mina.jpg"
              alt="Minería moderna"
              className="w-full h-full object-cover filter grayscale group-hover:grayscale-0 transition-all duration-700"
            />
          </div>

          {/* Contenido con animaciones */}
          <div className="space-y-12">
            <div 
              className={`transition-all duration-1000 
                ${isVisible.title 
                  ? 'opacity-100 translate-y-0' 
                  : 'opacity-0 translate-y-10'
                }`}
            >
              <h2 className="text-5xl font-light tracking-tight text-white/90 mb-6">
                Adquisición de Minas
              </h2>
              <p className="text-white/60 text-xl max-w-xl leading-relaxed">
                Transformando desafíos en oportunidades estratégicas para inversiones mineras seguras y rentables.
              </p>
            </div>

            <div className="grid md:grid-cols-2 gap-8 pb-8">
              {features.map((feature, index) => (
                <div 
                  key={index} 
                  className={`group border-l-2 border-white/20 pl-6 transition-all duration-1000 
                    ${isVisible.features[index] 
                      ? 'opacity-100 translate-x-0' 
                      : 'opacity-0 translate-x-10'
                    }`}
                  style={{ transitionDelay: `${index * 200}ms` }}
                >
                  <div className="mb-4 opacity-70 group-hover:opacity-100 transition-opacity">
                    {feature.icon}
                  </div>
                  <h3 className="text-lg font-medium text-white/90 mb-3">
                    {feature.title}
                  </h3>
                  <p className="text-white/60 text-sm">
                    {feature.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MiningSection;